<template>
	<v-app>
		<v-main>
			<v-container class="fill-height" fluid>
				<v-row align="center" justify="center">
					<v-col cols="12" sm="8" md="4" lg="3" xl="2">
						<v-card class="elevation-12" loading>
							<v-card-text class="text-h4 text-center">
								Logout...
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
/**********************************************************************************************
**** LOGOUT ***********************************************************************************
***********************************************************************************************
* logout come pagina per risolvere il bug #853
*/

export default {
	name: 'LogoutPage',
	components: {  },
	data: () => {
		return {
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
	},
	mounted() {
		// esegue il logout al mount
		this.$store.dispatch('logout')
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>